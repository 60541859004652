import moment from 'moment-timezone';
import { useMemo, useState } from 'react';
import { Card, Form, InputGroup, Spinner, Tab, Tabs } from 'react-bootstrap';
import ReactLoading from 'react-loading';
import { useNavigate } from 'react-router-dom';
import {
  UseSaveStudentInfo,
  useParentList,
  useSaveUser,
  useStudentList,
} from '../services/Query';
import { useForm } from 'react-hook-form';
import { Student } from '../models/student';
import { Parent } from '../models/parent';
import { SSNModal } from '../components/SSNModal';
const creditLevels = [
  'No credits earned',
  'First Year',
  'Second Year',
  'Third Year',
  'Fourth Year',
];
type FrmUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  password: string;
  confirm_password: string;
};
const Students = () => {
  const {
    isLoading: userLoading,
    data: users,
    isFetching: userFetching,
    error: errorLoading,
    refetch: getUsers,
  } = useStudentList();
  const {
    register,
    watch,
    reset,
    getValues,
    formState: { isValid },
  } = useForm<FrmUser>({ mode: 'onChange' });
  const [isEdit, setIsEdit] = useState(false);
  const [isSpring, setIsSpring] = useState(false);
  const { mutate: saveStudent, isLoading: isSaving } = UseSaveStudentInfo();
  const [showSSNModal, setShowSSNModal] = useState(false);
  const renderTime = (vTime: string) => {
    if (vTime != '') {
      const pos = vTime.indexOf('.');
      const rTime = vTime.substring(0, pos) + '-00:00';
      return moment.tz(rTime, 'America/New_York').format('YYYY-MM-DD HH:mm:ss');
    } else return vTime;
  };
  const onRegUser = () => {
    const data = getValues();
    saveStudent(JSON.stringify(data), {
      onSuccess: () => {
        getUsers();
        setIsEdit(false);
      },
      onError: () => {
        alert('error');
      },
    });
  };
  const onEditItem = (item: Student) => {
    setIsEdit(true);
    reset({
      id: item.id,
      firstName: item.firstname,
      lastName: item.lastName,
      email: item.email,
      confirmEmail: item.email,
      password: '',
      confirm_password: '',
    });
  };
  const userList = useMemo(() => {
    if (users) {
      const { students, finances } = users;
      return students
        .filter((it) => (it.isSpring25 || false) === isSpring)
        .map((it) => {
          const fin = finances?.find((f) => f.studentId === it.id);
          return {
            ...it,
            creditYear: fin?.creditYear,
          };
        });
    }
    return [];
  }, [users, isSpring]);
  if (userLoading || userFetching) {
    return (
      <div className="w-100 text-center py-4 ">
        <ReactLoading
          type="spinningBubbles"
          className="mx-auto my-auto"
          height={'80px'}
          width={'80px'}
          color="#666666"
        />
      </div>
    );
  }
  if (isEdit) {
    return (
      <div>
        <Card className="w-50">
          <Card.Header>Add User</Card.Header>
          <Card.Body>
            <div className="d-grid gap-3">
              <InputGroup>
                <InputGroup.Text>First Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('firstName', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Last Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('lastName', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Email</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('email', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Confirm Email</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('confirmEmail', {
                    validate: (val: string) => {
                      if (watch('email') != val) return 'mismatch';
                    },
                  })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>password</InputGroup.Text>
                <Form.Control
                  type="password"
                  {...register('password', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>confirm password</InputGroup.Text>
                <Form.Control
                  type="password"
                  {...register('confirm_password', {
                    validate: (val: string) => {
                      if (watch('password') != val) return 'mismatch';
                    },
                  })}
                />
              </InputGroup>
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end">
            <button
              className="bg-white rounded-2 px-3"
              disabled={!isValid}
              onClick={onRegUser}
            >
              {isSaving && (
                <Spinner
                  animation="border"
                  variant="secondary"
                  size="sm"
                  className="me-1"
                />
              )}
              Save
            </button>
            <button
              className="bg-white rounded-2 px-3 ms-2"
              onClick={() => {
                setIsEdit(false);
              }}
            >
              close
            </button>
          </Card.Footer>
        </Card>
      </div>
    );
  }
  return (
    <div>
      <div className="d-flex mb-2 justify-content-between">
        <div className="d-flex">
          <div className="gap-2">
            <div className="d-flex">
              <h6 className="text-muted mb-0">Total Users:</h6>
              <h6 className="ms-1 mb-0">{users?.students?.length}</h6>
            </div>
            <div className="d-flex mt-2">
              <h6 className="text-muted mb-0">
                {isSpring ? 'Second Semester Users: ' : 'Full Academic Users: '}
              </h6>
              <h6 className="ms-1">{userList.length}</h6>
            </div>
          </div>

          <Form.Check // prettier-ignore
            type="switch"
            className="ms-4 bg-[#3FC6AD] border-[#3FC6AD]"
            checked={isSpring}
            onClick={() => {
              setIsSpring((prev) => !prev);
            }}
            label="Second Semester"
          />
          {/* <h6 className="text-muted ms-4 mb-0">Total verified Users:</h6>
          <h6 className="ms-1 mb-0">
            {users?.filter((it) => it.member_Type === 'contributor').length}
          </h6>
          <h6 className="text-muted ms-4 mb-0">Total regular Users:</h6>
          <h6 className="ms-1 mb-0">
            {users?.filter((it) => it.member_Type !== 'contributor').length}
          </h6> */}
        </div>
        <div className="d-flex">
          {/* <button
            className="bg-white rounded-2 px-3 me-3"
            onClick={() => {
              setShowSSNModal(true);
            }}
          >
            View Number
          </button> */}
          {/* <button
            className="bg-white rounded-2 px-3"
            onClick={() => {
              reset({
                id: undefined,
                firstName: '',
                lastName: '',
                email: '',
                confirmEmail: '',
                password: '',
                confirm_password: '',
              });
              setIsEdit(true);
            }}
          >
            add student
          </button> */}
        </div>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            {/* <th></th> */}
            <th scope="col">First Name</th>
            <th scope="col">Last Name</th>
            <th scope="col">Email</th>
            {isSpring ? <th scope="col">Credit(s) earned</th> : null}
            <th scope="col">Login Time</th>
            {/* <td></td> */}
            {/* <th>role</th> */}
            {/* <th>valid</th> */}
          </tr>
        </thead>
        <tbody>
          {userList.map((it, idx) => (
            <tr key={idx}>
              {/* <td>{idx + 1}</td> */}
              <td>{it.firstname}</td>
              <td>{it.lastName}</td>
              <td>{it.email}</td>
              {isSpring ? <td>{creditLevels[it.creditYear || 0]}</td> : null}
              <td>{renderTime(it.modifiedDate || '')}</td>
              {/* <td>
                  <button
                    type="button"
                    className="bg-white rounded-2 px-3 ms-2"
                    onClick={() => onEditItem(it)}
                  >
                    edit
                  </button>
                </td> */}
              {/* <td>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={renderType(it.member_Type)}
                    checked={isContributor(it.member_Type)}
                    onClick={() => {
                      if (isContributor(it.member_Type)) {
                        setShowMessage(msgRole2);
                      } else {
                        setShowMessage(msgRole1);
                      }
                      checkType.current = 1;
                      setShowConfirm(true);
                      memberId.current = it.member_Id;
                    }}
                  />
                </td> */}
              {/* <td>
                  <Form.Check
                    type='switch'
                    id='custom-switch'
                    label={renderValid(it.member_Verified)}
                    checked={it.member_Verified}
                    onClick={() => {
                      setshowMessage(
                        it.member_Verified ? msgUser1 : msgUser2
                      );
                      setshowConfirm(true);
                      checkType.current = 2;
                      memberId.current = it.member_Id;
                    }}
                  />
                </td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <SSNModal
        show={showSSNModal}
        onDiscard={() => {
          setShowSSNModal(false);
        }}
        onOk={() => {
          setShowSSNModal(false);
        }}
      />
      {/* <ConfirmModal
        show={showConfirm}
        content={showMessage}
        leftTitle={'no'}
        rightTitle={'yes'}
        onOk={() => {
          setShowConfirm(false);
        }}
        onDiscard={() => {
          afterDiscard();
        }}
      /> */}
    </div>
  );
};
const Parents = () => {
  const {
    isLoading: userLoading,
    data: users,
    isFetching: userFetching,
    error: errorLoading,
    refetch: getUsers,
  } = useParentList();
  const {
    register,
    reset,
    getValues,
    formState: { isValid },
  } = useForm<FrmUser>({ mode: 'onChange' });
  const [isEdit, setIsEdit] = useState(false);
  const renderTime = (vTime: string) => {
    if (vTime != '') {
      const pos = vTime.indexOf('.');
      const rTime = vTime.substring(0, pos) + '-00:00';
      return moment.tz(rTime, 'America/New_York').format('YYYY-MM-DD HH:mm:ss');
    } else return vTime;
  };
  const { mutate: saveUser, isLoading: isSaving } = useSaveUser();
  const onRegUser = () => {
    const data = getValues();
    saveUser(JSON.stringify(data), {
      onSuccess: () => {
        getUsers();
        setIsEdit(false);
      },
      onError: () => {
        alert('error');
      },
    });
  };
  const onEditItem = (item: Parent) => {
    setIsEdit(true);
    reset({
      id: item.id,
      firstName: item.firstName,
      lastName: item.lastName || '',
    });
  };
  if (userLoading || userFetching) {
    return (
      <div className="w-100 text-center py-4 ">
        <ReactLoading
          type="spinningBubbles"
          className="mx-auto my-auto"
          height={'80px'}
          width={'80px'}
          color="#666666"
        />
      </div>
    );
  }
  if (isEdit) {
    return (
      <div>
        <Card className="w-50">
          <Card.Header>Add User</Card.Header>
          <Card.Body>
            <div className="d-grid gap-3">
              <InputGroup>
                <InputGroup.Text>First Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('firstName', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Last Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('lastName', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Email</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register('email', { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>Confirm Email</InputGroup.Text>
                <Form.Control type="text" {...register('confirmEmail')} />
              </InputGroup>
              {/* <InputGroup>
                <InputGroup.Text>School Name</InputGroup.Text>
                <Form.Control
                  type="text"
                  {...register("schoolName", { required: true })}
                />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>type of school</InputGroup.Text>
                <Form.Control type="text" {...register("typeOfSchool")} />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>country</InputGroup.Text>
                <Form.Control type="text" {...register("country")} />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>state/province</InputGroup.Text>
                <Form.Control type="text" {...register("province")} />
              </InputGroup>
              <InputGroup>
                <InputGroup.Text>city</InputGroup.Text>
                <Form.Control type="text" {...register("city")} />
              </InputGroup> */}
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end">
            <button
              className="bg-white rounded-2 px-3"
              disabled={!isValid}
              onClick={onRegUser}
            >
              {isSaving && (
                <Spinner
                  animation="border"
                  variant="secondary"
                  size="sm"
                  className="me-1"
                />
              )}
              Save
            </button>
            <button
              className="bg-white rounded-2 px-3 ms-2"
              onClick={() => {
                setIsEdit(false);
              }}
            >
              close
            </button>
          </Card.Footer>
        </Card>
      </div>
    );
  }
  return (
    <div>
      <div className="d-flex mb-2 justify-content-between">
        <div className="d-flex">
          <h6 className="text-muted mb-0">Total Users:</h6>
          <h6 className="ms-1 mb-0">{users?.length}</h6>
          {/* <h6 className="text-muted ms-4 mb-0">Total verified Users:</h6>
          <h6 className="ms-1 mb-0">
            {users?.filter((it) => it.member_Type === 'contributor').length}
          </h6>
          <h6 className="text-muted ms-4 mb-0">Total regular Users:</h6>
          <h6 className="ms-1 mb-0">
            {users?.filter((it) => it.member_Type !== 'contributor').length}
          </h6> */}
        </div>
        <div>
          {/* <button
            className="bg-white rounded-2 px-3"
            onClick={() => {
              reset();
              setIsEdit(true);
            }}
          >
            add stude
          </button> */}
        </div>
      </div>
      <table className="table table-bordered">
        <thead>
          <tr>
            {/* <th></th> */}
            <th scope="col">username</th>
            <th scope="col">email</th>
            <th scope="col">student</th>
            <th scope="col">created time</th>
            <th scope="col">updated time</th>
            {/* <td></td> */}
            {/* <th>role</th> */}
            {/* <th>valid</th> */}
          </tr>
        </thead>
        <tbody>
          {users &&
            users.map((it, idx) => (
              <tr key={idx}>
                {/* <td>{idx + 1}</td> */}
                <td>
                  {it.firstName} {it.lastName}
                </td>
                <td>{it.email}</td>
                <td>{it.createdByStudent?.email}</td>
                <td>{renderTime(it.createdDate || '')}</td>
                <td>{renderTime(it.updatedDate || '')}</td>
                {/* <td>
                  <button
                    type="button"
                    className="bg-white rounded-2 px-3 ms-2"
                    onClick={() => onEditItem(it)}
                  >
                    edit
                  </button>
                </td> */}
                {/* <td>
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label={renderType(it.member_Type)}
                    checked={isContributor(it.member_Type)}
                    onClick={() => {
                      if (isContributor(it.member_Type)) {
                        setShowMessage(msgRole2);
                      } else {
                        setShowMessage(msgRole1);
                      }
                      checkType.current = 1;
                      setShowConfirm(true);
                      memberId.current = it.member_Id;
                    }}
                  />
                </td> */}
                {/* <td>
                  <Form.Check
                    type='switch'
                    id='custom-switch'
                    label={renderValid(it.member_Verified)}
                    checked={it.member_Verified}
                    onClick={() => {
                      setshowMessage(
                        it.member_Verified ? msgUser1 : msgUser2
                      );
                      setshowConfirm(true);
                      checkType.current = 2;
                      memberId.current = it.member_Id;
                    }}
                  />
                </td> */}
              </tr>
            ))}
        </tbody>
      </table>
      {/* <ConfirmModal
        show={showConfirm}
        content={showMessage}
        leftTitle={'no'}
        rightTitle={'yes'}
        onOk={() => {
          setShowConfirm(false);
        }}
        onDiscard={() => {
          afterDiscard();
        }}
      /> */}
    </div>
  );
};
export const Users = () => {
  const navigate = useNavigate();

  return (
    <Tabs defaultActiveKey="student">
      <Tab eventKey="student" title="Students">
        <Students />
      </Tab>
      {/* <Tab eventKey="parent" title="parent">
        <Parents />
      </Tab> */}
    </Tabs>
  );
};
